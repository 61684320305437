<template>
<div v-if="!isloading" style="padding:5px" >
   
    
            <H1 class="qmajorheader" v-html="langConv('Sites Compared')"></H1> 
            <H2 class="qsubheader text-center">{{langConv('Check out what is really important when picking a bookmaker')}}</H2>  

            <div class="text-center qsmallfontplus"><span class="tick qsmallcircularpop"></span><span>a top performer for this category</span></div>

            <div class="grid-x qxsupersmallfont qtableheader text-center" style="margin-top:10px"> 
                <div @click="sortRefresh('Description')" class="cell small-2"  v-html="langConv('Name')"></div>
                <div @click="sortRefresh('Free_Games_Rating')" class="cell small-1"  v-html="langConv('Free Games')"></div>
        
                <div @click="sortRefresh('Cash_out_bets')" class="cell small-1"  v-html="langConv('Cash Out')"></div>
                <div @click="sortRefresh('Up_to_100_cashout')" class="cell small-1"  >100% {{langConv('Cash Out')}}</div>
            
                <div @click="sortRefresh('New_Player')" class="cell small-1"  v-html="langConv('New Player Bonus')"></div>
                <div @click="sortRefresh('All_Player')" class="cell small-1"  v-html="langConv('All Player Bonus')"></div>
                <div @click="sortRefresh('All_Player')" class="cell small-1"  v-html="langConv('Top Client Support')"></div>
                <div @click="sortRefresh('licenses')" class="cell medium-1 hide-for-small-only"  v-html="langConv('Gambling Regulation')"></div>
                <div @click="sortRefresh('nobets')" class="cell small-2"  v-html="langConv('Bets in last 3 months by OG')"></div>
            </div>

            <div v-for="(row,a) in sortedRows" :key="a" >

            <div class="grid-x medium-grid-frame qlargefont text-center" style="border-bottom:1px solid lightgray">
                <div class="cell small-2 text-center align-middle" >
                    <div :class="{tooltip: hints}" class="text-center" style="line-height:30px">
                        <a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.countryname" target="_blank">
                            <span :class="'b' + row.Description"></span>
                        </a>
                        <span class="qsmallcircularreg show-for-medium" style="margin-left:5px;padding:4px 5px 0px 5px"><sup>{{ row.Organisation_Type.toString().charAt(0)}}</sup></span>
                        <span class="tooltiptext tooltip-right show-for-medium" >{{langConv(row.Description) + ' - ' +  row.Organisation_Type}}</span>
                    </div>
                </div>
            

                <div class="cell small-1" style="line-height:30px">
                    <div v-bind:class="[{ tick: bVal(row.Free_Games_Rating),qsmallcircularpop: cVal(row.Free_Games_Rating) }]"></div>
                </div> 
                <div class="cell small-1" style="background-color: #efefef;line-height: 30px;">
                    <div v-bind:class="[{ tick: bVal(row.Cash_Out_Bets), qsmallcircularpop: cVal(row.Cash_Out_Bets)}]"></div>
                </div>
                <div class="cell small-1" style="line-height:30px">
                    <div v-bind:class="[{ tick: bVal(row.Up_to_100_Cashout), qsmallcircularpop: cVal(row.Up_to_100_Cashout)}]"></div>
                </div>
                <div class="cell small-1" style="background-color: #efefef;line-height: 30px;">
                    <div v-bind:class="[{ tick: bVal(row.New_Player), qsmallcircularpop: cVal(row.New_Player)} ]"></div>
                </div>
                <div class="cell small-1" :class="[{ tick: bVal(row.All_Player)}]" style="line-height:30px"></div>
                <div class="cell small-1" :class="[{ tick: bVal(row.First_Class_Support)}]" style="background-color: #efefef;line-height: 30px;"></div>
                <div class="cell medium-1 hide-for-small-only qmediumfont medium-cell-block" style="line-height:30px"  v-html="separatetoCountry(row.licenses)"></div>
                <div class="cell small-2 qmediumfont" style="background-color: #efefef;"><span :class="{qsmallcircularpop:(row.nobets > 10)}" style="text-align:center;" > {{ row.nobets }} </span></div> 

</div>



</div>

<!-- <div class="text-center">* On Oddsgalore site</div> -->

    
 </div>


</template>

<script>
  

    export default {

        name: "bookmakercomparison",
        data: function () {
            return {
                
                isloading: true,
                rows: [],
                currentSort: 'Description',
                currentSortDir: 'asc',
                sortedRows:[]
           
            };
        },

        mounted() {

            this.loadData();
        },

        computed: {
            hints () {
                return this.$store.state.showhints;
            }
        },
           
        methods: {

            separatetoCountry(val) {

                let flaghtml = "<div style='width:20vw' class='text-left'>";

                const countries = val.split(',');

                
                countries.forEach((item) =>
                   flaghtml = flaghtml + '<img src="/assets/images/flags/svg/' + item + '.svg" alt="' + item + '" style="width:20px;height:20px;margin-right:2px" title="' + item + '"/>'
                ) 

                flaghtml = flaghtml + "</div>";

                return flaghtml;

            },

            loadData: function () {        

            this.localJSONLoad(process.env.VUE_APP_DATA + "bookmakercomparison.json").then(response => {

                  
                    this.rows = response;  

                    this.sortRows();


                    this.isloading=false;

               }).catch((error) => {

                    console.log(error);
               })
            },

            //method called for changing sort on a column
            sortRefresh: function (s) {

                    if (s === this.currentSort) {
                                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                        }
                    this.currentSort = s;

                    this.sortRows();
            },


            sortRows: function() {

             this.sortedRows = this.rows.slice().sort((a, b) => {
                 let modifier = 1;
                 if (this.currentSortDir === 'desc') modifier = -1;
                 if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                 if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;

             });


             var countryname = this.$store.state.countryname;

             //is the license country in the list of licensedcountries
             this.sortedRows = this.sortedRows.filter(function(item) {
      

                let csv = item.licenses.split(",");
                csv = csv.filter(country => country.includes(countryname) && country.length == countryname.length) 

                if (csv.length == 1) { return true} else {return false}

            })
           
            },
            
        
            //this is working out whether there should be a tick
            bVal: function(val) {

                if (!val == '0' || !val == 0) {return true} else {return false}
            },

            //a 2 as a database entry means best in class = crown on screen

            cVal: function(val) {

                if (val == '2' || val == 2 ) {return true} else {return false}
            }
    }
}


</script>
<style  scoped>
@import "../style/bkmsmall.css";

</style>
